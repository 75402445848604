html,
body {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden;
  overflow-y: auto !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  isolation: isolate;
}

@keyframes color_anim {
  0% {
      fill: white;
  }
  50% {
      fill: #FBC638;
  }
  100% {
      fill: white;
  }
}
